import { defineStore } from 'pinia';
import { simpleApi } from '~/common/API/simpleApi';
export const useAppStore = defineStore('app', () => {
  const interests = ref<{id: number, name: string}[]>([]);


  // Получаем все интересы из бд
  (async () => {
    if (interests.value.length > 0) return;
    try {
      const endpoint = '/place/interests';
      const response = await simpleApi(endpoint);
      interests.value = response.interests;
    } catch (err) {
      console.error(err);
    }
  })();
  
  return {
    interests,
  }
});