import { simpleApi } from '~/common/API/simpleApi';
import { ElNotification } from 'element-plus';
import { IModerationUpdateRequest, PublisherResponse } from "~/interfaces/publisher/moderation";
import { pa } from 'element-plus/es/locale/index.mjs';

export default class PublisherService {

  static getPublisherPlaces = (userId: number, moderate = false) => {
    let endpoint = '/place/telegram/search';
    if (moderate) endpoint = '/moderation/search?status[]=rejected&status[]=pending&status[]=rework';
    return new Promise((resolve) => {
      simpleApi(endpoint, {
        params: {
          userId,
          perPage: 1000,
        },
      })
      .then((res) => {
        // let result = res.records;
        // console.log('result', result);
        // if (moderate) {
        //   result = result.filter(item => item.status === 'pending' || item.status === 'rework' || item.status === 'rejected');
        // }
        resolve(res.records);
      }).catch((err) => {
        console.error(err);
        resolve(false);
          ElNotification({
            title: 'Ошибка получения данных',
            message: 'попробуйте чуть позже или свяжитесь с администратором...',
            type: 'error',
          });
      });
    });
  };

  static getPublisherOrders = (params: any) => {
    const url = `/advert/publications`;
    return new Promise((resolve) => {
      simpleApi(url , {
        params
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static getPublicationCount = (userId: number) => {
    const url = `/publisher/publication/count`;
    return new Promise((resolve) => {
      simpleApi(url , {
        params: {
          id: userId,
        }
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static setPublisherEmail = (email: string, id: number) => {
    const url = `/user/set-email-request`;
    return new Promise((resolve) => {
      simpleApi(url, {
        method: 'POST',
        body: { email, id }
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
      });
    });
  }


  static publisherEmailConfirmation = async (code: string): Promise<PublisherResponse | null> => {
    const url = `/user/email-confirm`;
    try {
      const res = await simpleApi(url, {
        method: 'POST',
        body: { code }
      });
      if (!res) return null;
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static getPlaceModeration = (userId: number) => {
    const url = `/moderation/search`;
    return new Promise((resolve) => {
      simpleApi(url, {
        params: {
          'status[]': 'rework',
          userId,
        }
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
      });
    });
  }

  static updateModerationRequest = (data: IModerationUpdateRequest) => {
    const url = `/publisher/edit-place-moderation-request`;
    return new Promise((resolve) => {
      simpleApi(url, {
        method: 'PUT',
        body: data
      })
      .then((res) => {
        resolve(res);
      })
    });
  }

  static updateUser = (data: any) => {
    const url = `/admin/user/publisher/update`;
    return new Promise((resolve) => {
      simpleApi(url, {
        method: 'PUT',
        body: data
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
      });
    });
  }

  static orderReject = async (payload: {id: number, reason: string}) => {
    const endpoint = '/advert/publication/publisher-reject';
    try {
      const response = await simpleApi(endpoint, { method: 'PUT', body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static getPlaceOrder = async (payload: { placeId: null,ber}) => {
    const endpoint = '/advert/publications';
    try {
      const response = await simpleApi(endpoint, { params: payload })
      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

}
