<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_21583_17578)">
      <path
        d="M6.1875 17.5C6.51055 18.2428 7.04352 18.8751 7.72096 19.3193C8.3984 19.7634 9.1908 20 10.0008 20C10.8109 20 11.6033 19.7634 12.2807 19.3193C12.9581 18.8751 13.4911 18.2428 13.8142 17.5H6.1875Z"
        fill="#99ABBA"
      />
      <path
        d="M18.6598 10.4581L17.2132 5.68889C16.7499 4.02084 15.7427 2.55536 14.3514 1.52514C12.9601 0.494909 11.2646 -0.0410168 9.53393 0.00245023C7.8033 0.0459172 6.13678 0.666286 4.79898 1.76506C3.46118 2.86383 2.52879 4.37802 2.14984 6.06722L1.02651 10.6814C0.877201 11.2945 0.869112 11.9335 1.00285 12.5503C1.13659 13.167 1.40866 13.7452 1.79853 14.2415C2.18839 14.7377 2.68588 15.1388 3.25344 15.4147C3.82099 15.6905 4.44379 15.8339 5.07484 15.8339H14.6723C15.323 15.8339 15.9645 15.6816 16.5457 15.3891C17.1268 15.0966 17.6314 14.6721 18.019 14.1495C18.4066 13.627 18.6665 13.0209 18.7778 12.3799C18.889 11.7388 18.8486 11.0807 18.6598 10.4581Z"
        fill="#99ABBA"
      />
    </g>
    <defs>
      <clipPath id="clip0_21583_17578">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
