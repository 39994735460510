<template>
  <div class="menu-social-jet">
    <!-- <MenuSeparator>
      Рекламные кампании
    </MenuSeparator>
    <NuxtLink to="/order/1">
      <MenuItem :class="{ active: route.name.startsWith('order')}" >
        <template #icon>
          <i class="fi fi-sr-document-signed text-xl"></i>
        </template>
        <template #text>Заказы</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/creatives">
      <MenuItem :class="{ active: route.name === 'creatives'}">
        <template #icon>
          <i class="fi fi-sr-copy-alt text-xl"></i>
        </template>
        <template #text>Креативы</template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Настройки
    </MenuSeparator>
    <NuxtLink to="/balance">
      <MenuItem :class="{ active: route.name === 'balance'}">
        <template #icon>
          <i class="fi fi-sr-credit-card text-xl"></i>
        </template>
        <template #text>Баланс</template> -->
    <!-- TODO поставить реальный бланс -->
    <!-- <template #info>1 252₽</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/settings">
      <MenuItem :class="{ active: route.name === 'settings'}">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>
          Настройки
        </template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Сервис</MenuSeparator>
    <NuxtLink to="/news">
      <MenuItem :class="{ active: route.name === 'news'}">
        <template #icon>
          <i class="fi fi-sr-id-badge text-xl"></i>
        </template>
        <template #text>Новости</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/support">
      <MenuItem :class="{ active: route.name === 'support'}">
        <template #icon>
          <i class="fi fi-sr-time-twenty-four text-xl"></i>
        </template>
        <template #text>Поддержка</template>
      </MenuItem>
    </NuxtLink> -->

    <MenuSeparator>
      Рекламные кампании
    </MenuSeparator>
    <NuxtLink to="/adv/orders">
      <MenuItem :class="{ active: route.name === 'adv-orders' }">
        <template #icon>
          <i class="fi fi-sr-document-signed text-xl"></i>
        </template>
        <template #text>Заказы</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/adv/creatives">
      <MenuItem :class="{ active: route.name === 'adv-creatives' }">
        <template #icon>
          <i class="fi fi-sr-gallery text-xl"></i>
        </template>
        <template #text>
          Креативы
        </template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/adv/dialogs">
      <MenuItem :class="{ active: route.name === 'adv-dialogs' }">
        <template #icon>
          <i class="fi fi-sr-comment-alt text-xl"></i>
        </template>
        <template #text>
          Диалоги
        </template>
        <template #info>
          <div class="text-white text-xs font-extrabold bg-[#27C2A7] rounded-full px-1.5">
            {{ '+3' }}
          </div>
        </template>
      </MenuItem>
    </NuxtLink>
    <!-- <NuxtLink to="/adv/history">
      <MenuItem :class="{ active: route.name === 'adv-history' }">
        <template #icon>
          <svg
            class="mb-1 min-w-5 min-h-5"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_28979_4030)">
              <path
                d="M17.4245 9.1933V3.63731C17.4245 3.15497 17.2329 2.69239 16.8919 2.35133C16.5508 2.01026 16.0882 1.81866 15.6059 1.81866H11.8049C11.423 0.763835 10.4227 0 9.24059 0C8.05846 0 7.0582 0.763835 6.67629 1.81866H2.8753C1.87504 1.81866 1.05664 2.63705 1.05664 3.63731V16.3679C1.05664 16.8502 1.24825 17.3128 1.58931 17.6539C1.93038 17.9949 2.39296 18.1866 2.8753 18.1866H8.43129C9.57704 19.3141 11.1411 20.0052 12.8779 20.0052C16.397 20.0052 19.2432 17.159 19.2432 13.6399C19.2432 11.9031 18.5521 10.3391 17.4245 9.1933ZM9.24059 1.81866C9.74072 1.81866 10.1499 2.22785 10.1499 2.72798C10.1499 3.22811 9.74072 3.63731 9.24059 3.63731C8.74046 3.63731 8.33126 3.22811 8.33126 2.72798C8.33126 2.22785 8.74046 1.81866 9.24059 1.81866ZM3.78462 5.45597H14.6966V7.27462H3.78462V5.45597ZM6.58535 14.5492H3.78462V12.7306H6.58535C6.53989 13.0307 6.51261 13.3307 6.51261 13.6399C6.51261 13.9491 6.53989 14.2492 6.58535 14.5492ZM3.78462 10.9119V9.09328H8.43129C7.8766 9.61159 7.45831 10.2299 7.13095 10.9119H3.78462ZM12.8779 18.1866C10.3682 18.1866 8.33126 16.1497 8.33126 13.6399C8.33126 11.1302 10.3682 9.09328 12.8779 9.09328C15.3876 9.09328 17.4245 11.1302 17.4245 13.6399C17.4245 16.1497 15.3876 18.1866 12.8779 18.1866ZM13.3326 13.8672L15.9332 15.404L15.2512 16.5134L11.9686 14.5492V10.0026H13.3326V13.8672Z"
                fill="#374957"
              />
            </g>
            <defs>
              <clipPath id="clip0_28979_4030">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </template>
        <template #text>
          История размещений
        </template>
      </MenuItem>
    </NuxtLink> -->
    <NuxtLink to="/adv/orders/appeals">
      <MenuItem :class="{ active: route.name === 'adv-orders-appeals' }">
        <template #icon>
          <i class="fi fi-sr-shield-interrogation text-xl"></i>
        </template>
        <template #text>
          Обращения по заказам
        </template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Настройки
    </MenuSeparator>
    <NuxtLink to="/adv/balance">
      <MenuItem :class="{ active: route.name === 'adv-balance' }">
        <template #icon>
          <i class="fi fi-sr-credit-card text-xl"></i>
        </template>
        <template #text>Баланс</template>
        <template #info>1 252₽</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/adv/settings">
      <MenuItem :class="{ active: route.name === 'adv-settings' }">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>
          Настройки
        </template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Сервис
    </MenuSeparator>
    <NuxtLink to="/adv/news">
      <MenuItem :class="{ active: route.name === 'adv-news' }">
        <template #icon>
          <i class="fi fi-sr-id-badge text-xl"></i>
        </template>
        <template #text>Новости</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/adv/support">
      <MenuItem :class="{ active: route.name === 'adv-support' }">
        <template #icon>
          <i class="fi fi-sr-time-twenty-four text-xl"></i>
        </template>
        <template #text>
          Поддержка
        </template>
      </MenuItem>
    </NuxtLink>
  </div>
</template>

<script setup>
const route = useRoute();
</script>

<style>
a {
  width: 100%;
}

.menu-social-jet {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
</style>
