import { defineStore } from 'pinia';
import ModerationService from '~/services/ModerationService';
import { simpleApi } from '~/common/API/simpleApi';
import { da, en } from 'element-plus/es/locale/index.mjs';

export const useModerationStore = defineStore('moderation', () => {

  const moderateCount = ref<number>(0);
  const moderateOrders = ref<number>(0);

  const confirmModerate = async (payload: number) => {
    return new Promise((resolve) => {
      ModerationService.confirmModerate(payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        resolve(false);
      });
    });
  }

  const rejectModerate = async (payload: {channelId: number, reason: string, reject?: boolean}) => {
    return new Promise((resolve) => {
      ModerationService.rejectModerate(payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        resolve(false);
      });
    });
  }

  const newValuesFromAdmin = async (data: any) => {
    
    if (data.compare.length === 0 && Object.keys(data.moderate).length === 0) return;
    const saveData = {
      id: data.newValues.id,
    }

    // console.log('data', data);

    data.compare.forEach((item: any) => {

      if (item.type === 'categories') {
        saveData['categoriesIds'] = data.newValues['categoriesIds'];
      } else if (item.type === 'interests') {
        saveData['interestsIds'] = data.newValues['interestsIds'];
      } else if (item.type === 'subs') {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['count'] = +item.newValue;
      } else if (item.type === 'genderF') {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['femalePercent'] = +item.newValue;
      } else if (item.type === 'genderM') {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['malePercent'] = +item.newValue;
      } else if (item.type === 'views') {
        if (!saveData['viewsStatistic']) {
          saveData['viewsStatistic'] = {};
        }
        saveData['viewsStatistic']['countLast24h'] = +item.newValue;
      } else {
        saveData[item.type] = data.newValues[item.type];
      }
    });

    if (data.moderate) {
      if (data.moderate.subs) {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['count'] = +data.moderate.subs;
      }
      if (data.moderate.views) {
        if (!saveData['viewsStatistic']) {
          saveData['viewsStatistic'] = {};
        }
        saveData['viewsStatistic']['countLast24h'] = +data.moderate.views;
      }
      if (data.moderate.genderF) {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['femalePercent'] = +data.moderate.genderF;
      }
      if (data.moderate.genderM) {
        if (!saveData['subscribersStatistic']) {
          saveData['subscribersStatistic'] = {};
        }
        saveData['subscribersStatistic']['malePercent'] = +data.moderate.genderM;
      } 
    };

    // console.log('saveData', { ...data.newValues, ...saveData});

    return new Promise((resolve) => {
      simpleApi("/place/telegram/update", { method: 'PUT', body: { ...data.newValues, ...saveData} }) // Выполняем запрос к API с использованием simpleApi
      .then((res) => {
        console.log(res);
        resolve(res); // Возвращаем ответ
      }).catch((err) => {
        console.error(err); // Логируем ошибку
        resolve(false); // Возвращаем false в случае ошибки
      });
    });
  }

  const getAlerts = async () => {
    try {
      const res = await ModerationService.getModerationCount({query: '?status[]=pending'});
      if (res && res.moderationRequestsCount) {
        moderateCount.value = res.moderationRequestsCount.find((item: any) => item.status === 'pending')?.count || 0;
      }
    } catch (err) {
      console.error(err); // Обработка ошибки, если необходимо
    }
    // todo: запросить нормальный метод для получения заказов в статусе pending
    try {
      const res = await ModerationService.getAllOrders({
       params: {
        'sortDirections[]': 'desc',
        'sortFields[]': 'id'
      }});
      const moderate = res.records.filter((order) => order.status === 'payed' || order.status === 'on-admin-moderation');
      moderateOrders.value = moderate.length;
    } catch (err) {
      console.error(err);
    }
    
  }

  const getPublishers = async (data: any) => {
    
    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '') {
          delete data[key];
          return;
        }
        if (key === 'registration') {
          data['createdAtFrom'] = new Date(data[key][0]).toISOString();
          data['createdAtTo'] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === 'text') {
          data['name'] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      
      });
    }

    if (data.ordersFrom && !data.ordersTo) {
      data['ordersFrom'] = +data.ordersFrom;
      data['ordersTo'] = 10000;
    }

    if (data.ordersTo && !data.ordersFrom) {
      data['ordersFrom'] = 0;
      data['ordersTo'] = +data.ordersTo;
    }

    if (data.ordersFrom && data.ordersTo) {
      data['ordersFrom'] = +data.ordersFrom;
      data['ordersTo'] = +data.ordersTo;
    }

    if (data.balanceFrom && !data.balanceTo) {
      data['balanceFrom'] = +data.balanceFrom;
      data['balanceTo'] = 1000000;
    }

    if (data.balanceTo && !data.balanceFrom) {
      data['balanceFrom'] = 0;
      data['balanceTo'] = +data.balanceTo;
    }

    if (data.balanceFrom && data.balanceTo) {
      data['balanceFrom'] = +data.balanceFrom;
      data['balanceTo'] = +data.balanceTo;
    }

    if (data.publisherChannelsFrom && !data.publisherChannelsTo) {
      data['publisherChannelsFrom'] = +data.publisherChannelsFrom;
      data['publisherChannelsTo'] = 1000;
    }

    if (data.publisherChannelsTo && !data.publisherChannelsFrom) {
      data['publisherChannelsFrom'] = 0;
      data['publisherChannelsTo'] = +data.publisherChannelsTo;
    }

    if (data.publisherChannelsFrom && data.publisherChannelsTo) {
      data['publisherChannelsFrom'] = +data.publisherChannelsFrom;
      data['publisherChannelsTo'] = +data.publisherChannelsTo;
    }

    try {
      const response = await ModerationService.getPublishers(
        {params: {
          ...data, 
          role: 3,
          'sortDirections[]': ['desc'],
          'sortFields[]': ['id'],
        }
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const getAdmins = async () => {
    try {
      const response = await ModerationService.getAdvertisers(
        {params: {
          role: 4,
          'sortDirections[]': 'desc',
          'sortFields[]': 'id',
        }
      });
      console.log(response);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const getAdvertisers = async (data: any) => {

    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '') {
          delete data[key];
          return;
        }
        if (key === 'registration') {
          data['createdAtFrom'] = new Date(data[key][0]).toISOString();
          data['createdAtTo'] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === 'text') {
          data['name'] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      
      });
    }

    if (data.ordersFrom && !data.ordersTo) {
      data['ordersFrom'] = +data.ordersFrom;
      data['ordersTo'] = 10000;
    }

    if (data.ordersTo && !data.ordersFrom) {
      data['ordersFrom'] = 0;
      data['ordersTo'] = +data.ordersTo;
    }

    if (data.ordersFrom && data.ordersTo) {
      data['ordersFrom'] = +data.ordersFrom;
      data['ordersTo'] = +data.ordersTo;
    }


    try {
      const response = await ModerationService.getAdvertisers(
        {params: {
          ...data, 
          role: 1,
          'sortDirections[]': 'desc',
          'sortFields[]': 'id',
        }
      });
      console.log(12, response.meta);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const getUsers = async (data: any) => {
    try {
      const response = await ModerationService.getAdvertisers(
        {params: {
          ...data, 
          role: 1,
          // 'sortDirections[]': 'desc',
          // 'sortFields[]': 'id',
        }
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const getChannels = async (data: any, filter: any) => {
      Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '') {
          delete data[key];
        }
      });
      let query = '';

    if (filter.filter === 'new') {
      data["status[]"] = 'pending';
    }
    if (filter.filter === 'notNew') {
      data["status[]"] = ['approved', 'rejected', 'rework'];
    }
    if (filter.status === 'approved') {
      data["status[]"] = ['approved'];
    }
    if (filter.status === 'rejected') {
      data["status[]"] = ['rejected', 'rework'];
    }
    if (filter.date) {
      data.createdAtFrom = filter.date[0].toISOString();
      data.createdAtTo = filter.date[1].toISOString();
    }

    data["sortDirections[]"] = ['desc'];
    data["sortFields[]"] = ['id'];


    try {
      const response = await ModerationService.getChannels({ params: {...data}, query });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const getAllChannels = async (data: any) => {

    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '') {
          delete data[key];
          return;
        }
        if (key === 'registration') {
          data['createdAtFrom'] = new Date(data[key][0]).toISOString();
          data['createdAtTo'] = new Date(data[key][1]).toISOString();
          delete data[key];
        }

        if (key === 'status' && data[key] === 'all') {
          delete data[key];
        }

        if (data.ordersFrom && !data.ordersTo) {
          data['ordersFrom'] = +data.ordersFrom;
          data['ordersTo'] = 10000;
        }

        if (data.ordersTo && !data.ordersFrom) {
          data['ordersFrom'] = 0;
          data['ordersTo'] = +data.ordersTo;
        }

        if (data.ordersFrom && data.ordersTo) {
          data['ordersFrom'] = +data.ordersFrom;
          data['ordersTo'] = +data.ordersTo;
        }

        if (key === 'text') {
          data['name'] = data[key];
          // data['telegramId'] = data[key];
          delete data[key];
        }
      
      });
    }

    try {
      const response = await ModerationService.getAllChannels({ params: {...data} });
      console.log(13, response.meta);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  

  const getAllOrders = async (data: any) => {
    try {
      const response = await ModerationService.getAllOrders(
        { params: 
          {
          ...data,
          'sortDirections[]': 'desc',
          'sortFields[]': 'id'
          }
        });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  // const getModerateOrders = async (data: any) => {
  //   try {
  //     const response = await ModerationService.getAllOrders({ params: {...data}, query: '?page[perPage]=100' });
  //     return response;
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  const deleteModeration = async (data: any) => {
    try {
      const response = await ModerationService.deleteModeration(data);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const updateAdmin = async (data: any) => {
    try {
      const response = await ModerationService.updateAdmin(data);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  const updatePublisher = async (data: {
    id: number,
    name?: string,
    telegramId?: string,
    email?: string
  }) => {
    try {
      const response = await ModerationService.updatePublisher(data);
      if (response ) {
        ElNotification({
          title: 'Успешное обновление',
          message: 'Спасибо!',
          type: 'success',
        });
      }
      console.log(response);
    } catch (err) {
      console.log(err);
      ElNotification({
        title: 'Ошибка обновления',
        message: 'попробуйте чуть позже или свяжитесь с администратором...',
        type: 'warning',
      });
    }
  }

  const publicationReject = async (data: {
    reason?: string
    id: number
  }) => {
    try {
      const response = await ModerationService.publicationReject(data);
      if (response) {
        ElNotification({
          title: 'Успешное обновление',
          message: 'Спасибо!',
          type: 'success',
        });
      }
      return response;
    } catch (err) {
      console.log(err);
      ElNotification({
        title: 'Ошибка обновления',
        message: 'попробуйте чуть позже или свяжитесь с администратором...',
        type: 'warning',
      });
    }
  }

  const publicationApprove = async (data: { id: number }) => {
    try {
      const method = 'PUT';
      const endpoint = '/advert/publication/admin-approve';
      const response = await ModerationService.universalApi(data, endpoint, method);
      if (response) {
        ElNotification({
          title: 'Успешное обновление',
          message: 'Спасибо!',
          type: 'success',
        });
      }
      return response;
    } catch (err) {
      console.log(err);
      ElNotification({
        title: 'Ошибка обновления',
        message: 'попробуйте чуть позже или свяжитесь с администратором...',
        type: 'warning',
      });
    }
  }


  getAlerts();



  return {
    moderateCount,
    moderateOrders,
    confirmModerate,
    rejectModerate,
    newValuesFromAdmin,
    getAlerts,
    getAdmins,
    getPublishers,
    getAdvertisers,
    getUsers,
    getChannels,
    getAllChannels,
    getAllOrders,
    deleteModeration,
    updateAdmin,
    updatePublisher,
    publicationReject,
    publicationApprove
  };
});
