<template>
  <div class="menu-social-jet">
    <!--    <MenuSeparator>-->
    <!--      Рекламные кампании-->
    <!--    </MenuSeparator>-->
    <NuxtLink to="/publisher">
      <PublisherMenuItem :class="{ active: main_routes.includes(route.name) }">
        <template #icon>
          <i class="fi fi-sr-apps text-xl"></i>
        </template>
        <template #text>Мои каналы</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/orders">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-orders' }">
        <template #icon>
          <i class="fi fi-sr-journal-alt text-xl"></i>
        </template>
        <template #text>Заявки</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/dialogs">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-dialogs' }">
        <template #icon>
          <i class="fi fi-sr-comment-alt text-xl"></i>
        </template>
        <template #text>Диалоги</template>
        <template #notice><div class="chats-count">+3</div></template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/history">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-history' }">
        <template #icon>
          <Icon name="mdi:clipboard-text-time" size="24" :class="{ 'text-[#00AEE8]': route.name === 'publisher-history' }"/>
        </template>
        <template #text>История размещений</template>
      </PublisherMenuItem>
    </NuxtLink>

        <MenuSeparator>
          Настройки
        </MenuSeparator>

    <NuxtLink to="/publisher/payments">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-payments' }">
        <template #icon>
          <i class="fi fi-sr-credit-card text-xl"></i>
        </template>
        <template #text>Выплаты</template>
        <!-- TODO поставить реальный бланс -->
        <template #info>1 252₽</template>
      </PublisherMenuItem>
    </NuxtLink>

    <NuxtLink to="/publisher/settings">
      <PublisherMenuItem :class="{ active: route.name === 'publisher-settings' }">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>Настройки</template>
      </PublisherMenuItem>
    </NuxtLink>

    <MenuSeparator>
      Сервис
    </MenuSeparator>

    <NuxtLink to="/creatives">
      <PublisherMenuItem :class="{ active: route.name === 'creatives' }">
        <template #icon>
          <i class="fi fi-sr-id-badge text-xl"></i>
        </template>
        <template #text>Новости</template>
      </PublisherMenuItem>
    </NuxtLink>



    <NuxtLink to="/support">
      <PublisherMenuItem :class="{ active: route.name === 'support' }">
        <template #icon>
          <i class="fi fi-sr-time-twenty-four text-xl"></i>
        </template>
        <template #text>Поддержка</template>
      </PublisherMenuItem>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const main_routes = ref(['publisher', 'publisher-places-new', 'publisher-places-id']);
</script>

<style>
a {
  width: 100%;
}
.menu-social-jet {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
.chats-count {
  color: white;
  background: #27C2A7;
  border-radius: 15px;
  text-align: center;
  height: 16px;
  width: 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 800;
  cursor: pointer;
}
</style>
