<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_21583_17583)">
      <path
        d="M18.9275 3.3975C18.6931 3.1162 18.3996 2.88996 18.0679 2.73485C17.7363 2.57973 17.3745 2.49955 17.0083 2.5H3.535L3.5 2.2075C3.42837 1.59951 3.13615 1.03894 2.67874 0.632065C2.22133 0.225186 1.63052 0.000284828 1.01833 0L0.833333 0C0.61232 0 0.400358 0.0877974 0.244078 0.244078C0.0877974 0.400358 0 0.61232 0 0.833333C0 1.05435 0.0877974 1.26631 0.244078 1.42259C0.400358 1.57887 0.61232 1.66667 0.833333 1.66667H1.01833C1.22244 1.66669 1.41945 1.74163 1.57198 1.87726C1.72451 2.0129 1.82195 2.19979 1.84583 2.4025L2.9925 12.1525C3.11154 13.1665 3.59873 14.1015 4.36159 14.78C5.12445 15.4585 6.10988 15.8334 7.13083 15.8333H15.8333C16.0543 15.8333 16.2663 15.7455 16.4226 15.5893C16.5789 15.433 16.6667 15.221 16.6667 15C16.6667 14.779 16.5789 14.567 16.4226 14.4107C16.2663 14.2545 16.0543 14.1667 15.8333 14.1667H7.13083C6.61505 14.1652 6.11233 14.0043 5.69161 13.7059C5.27089 13.4075 4.95276 12.9863 4.78083 12.5H14.7142C15.6911 12.5001 16.6369 12.1569 17.3865 11.5304C18.1361 10.9039 18.6417 10.0339 18.815 9.0725L19.4692 5.44417C19.5345 5.08417 19.5198 4.71422 19.4262 4.36053C19.3326 4.00684 19.1623 3.67806 18.9275 3.3975Z"
        fill="#99ABBA"
      />
      <path
        d="M5.83268 19.9974C6.75316 19.9974 7.49935 19.2512 7.49935 18.3307C7.49935 17.4103 6.75316 16.6641 5.83268 16.6641C4.91221 16.6641 4.16602 17.4103 4.16602 18.3307C4.16602 19.2512 4.91221 19.9974 5.83268 19.9974Z"
        fill="#99ABBA"
      />
      <path
        d="M14.1667 19.9974C15.0871 19.9974 15.8333 19.2512 15.8333 18.3307C15.8333 17.4103 15.0871 16.6641 14.1667 16.6641C13.2462 16.6641 12.5 17.4103 12.5 18.3307C12.5 19.2512 13.2462 19.9974 14.1667 19.9974Z"
        fill="#99ABBA"
      />
    </g>
    <defs>
      <clipPath id="clip0_21583_17583">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
