import { simpleApi } from '~/common/API/simpleApi';
import { ElNotification } from 'element-plus';

interface IGetChannelsParams {
  entityType?: string;
  status?: string[];
  purpose?: string[];
  userId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  page?: number;
  pageSize?: number;
}

interface IUniversalPlayloads extends IGetChannelsParams {
    id?: number;
    name?: string;
    telegramId?: string;
    email?: string;
    reason?: string;
  }

export default class ModerationService {

  static getChannels = async (data: { params?: IGetChannelsParams, query?: string } = {}) => {
    let query = `${data.query ? '&' + data.query : ''}`;
    if (query[0] === '?') {
      query = '&' + query.slice(1);
    }
    const endpoint = `/moderation/search`;
    return new Promise((resolve) => {
      simpleApi(endpoint, { params: data.params, query: query })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
          // ElNotification({
          //   title: 'Ошибка получения данных',
          //   message: 'попробуйте чуть позже или свяжитесь с администратором...',
          //   type: 'error',
          // });
      });
    });
  };

  static confirmModerate = async (payload: number) => {
    const endpoint = '/moderation/approve';
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: 'PUT', body: { id: payload } })
      .then((res) => {
        ElNotification({
            title: 'Успешная модерация',
            message: 'канал успешно прошел модерацию',
          type: 'success',
        });
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
        ElNotification({
          title: 'Ошибка модерирования канала',
          message: 'попробуйте чуть позже или свяжитесь с администратором...',
          type: 'error',
        });
      });
    });
  }

  // Метод для отклонения модерации канала
  static rejectModerate = async (payload: {channelId: number, reason: string, reject?: boolean}) => {
    // Определяем конечную точку API в зависимости от значения reject
    const endpoint = payload.reject ? '/moderation/reject' : '/moderation/rework';
    return new Promise((resolve) => {
      // Выполняем запрос к API с использованием simpleApi
      simpleApi(endpoint, { method: 'PUT', body: { id: payload.channelId, reason: payload.reason } })
      .then((res) => {
        // Уведомление об успешном отклонении модерации
        ElNotification({
            title: 'Успешное отклонение',
            message: 'Модерация отклонена',
          type: 'success',
        });
        resolve(res); // Возвращаем ответ
      }).catch((err) => {
        console.error(err); // Логируем ошибку
        resolve(false); // Возвращаем false в случае ошибки
        // Уведомление об ошибке при отклонении модерации
        ElNotification({
          title: 'Ошибка модерирования канала',
          message: 'попробуйте чуть позже или свяжитесь с администратором...',
          type: 'error',
        });
      });
    });
  }

  // Метод для получения количества запросов на модерацию
  static getModerationCount = async (data: { query?: string } = {}) => {
    const query = data.query || ''; // Получаем строку запроса или устанавливаем пустую строку, если она не задана
    const endpoint = `/moderation/count`; // Устанавливаем конечную точку API
    return new Promise((resolve) => {
      simpleApi(endpoint, { query: data.query }) // Выполняем запрос к API с использованием simpleApi
      .then((res) => {
        resolve(res); // Возвращаем ответ
      }).catch((err) => {
        console.error(err); // Логируем ошибку
        resolve(false); // Возвращаем false в случае ошибки
      });
    });
  }

  static getPublishers = async (data: { params?: IGetChannelsParams}) => {
    const endpoint = '/admin/user/publishers';
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
      .then((res) => {
        console.log(res.meta)
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static getAdvertisers = async (data: { params?: IGetChannelsParams}) => {
    // const endpoint = '/admin/user/advertisers?sortDirections[]=desc&sortFields[]=id';
    const endpoint = '/admin/user/advertisers';
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static getAllChannels = async (data: { params?: IGetChannelsParams}) => {
    const emdpoint = '/place/telegram/search?sortDirections[]=desc&sortFields[]=id';
    return new Promise((resolve) => {
      simpleApi(emdpoint, data)
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static getAllOrders = async (data: { params?: IGetChannelsParams }) => {
    const endpoint = `/advert/orders`;
    return new Promise((resolve) => {
      simpleApi(endpoint, data)
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }


  static deleteModeration = async (payload: number) => {
    if (!payload) {
      ElNotification({
          title: 'Ошибка удаления канала',
          message: 'попробуйте чуть позже или свяжитесь с администратором...',
          type: 'error',
        });
        return;
    }
    const endpoint = '/moderation/delete';
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: 'DELETE', body: { id: payload } })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static updateAdmin = async (payload: {
    id: number, 
    role: number[], 
    name: string, 
    email: string, 
    telegram: string
  }) => {
    const endpoint = '/admin/user/advertiser/update';
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: 'PUT', body: payload })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
      });
    });
  }

  static updatePublisher = async (payload: {
    id: number,
    name?: string,
    telegramId?: string,
    email?: string
  }) => {
    const endpoint = '/admin/user/publisher/update';
    try {
      const response = await simpleApi(endpoint, { method: 'PUT', body: payload });
      if (response) {
        return response
      }
      return null;
    } catch (error) {
      console.error(err);
      return null;
    }
  }


  static publicationReject = async (payload: {
    reason?: string,
    id: number
  }) => {
    const endpoint = '/advert/publication/admin-reject';
    try {
      const response = await simpleApi(endpoint, { method: 'PUT', body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log('Error 1', error);
      return null;
    }
  }

  
  static universalApi = async (payload: IUniversalPlayloads, endpoint: string, method: string) => {
    try {
      const response = await simpleApi(endpoint, { method: method, body: payload });
      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.log('Error 1', error);
      return null;
    }
  }
}
